import React, { useContext, useEffect, useState } from "react";

import CenterIcon from "../../components/ui/CenterIcon";

import profile from "../../assets/profile.svg";
import AuthContext from "../../context/AuthContext";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import { profileStatistics } from "../../services/defaultSettings";
import UserStatistics from "./UserStatistics";

const UserHome = () => {
    const { user } = useContext(AuthContext);

    return (
        <>
            <div className="flex flex-col sm:flex-row sm:space-x-8 sm:space-x-reverse">
                <div className="sm:basis-1/4 p-2">
                    <img src={profile} alt="profile" />
                </div>
                <div className="sm:basis-3/4">
                    <div className="font-h2">{user.full_name}</div>
                    {/* <div className="py-3">
                    <div className="font-w-bold">النقاط</div>
                    <FlexRowReverse className="font-smaller">
                        <div>0</div>
                        <div>نقاط</div>
                    </FlexRowReverse>
                    <div className="h-2 w-full max-w-md rounded-full bg-yellow-200 dark:bg-yellow-800 smooth">
                        <div
                            className="h-full rounded-full bg-yellow-500"
                            style={{ width: "3%" }}
                        ></div>
                    </div>
                </div> */}
                    <div className="h-1 w-full bg-outer-container rounded-full my-5 smooth"></div>
                    <div className="">
                        <FlexRowReverse>
                            <CenterIcon
                                icon={"ant-design:phone-twotone"}
                                className="text-cyan-500"
                            />
                            <span className="flex-center-both">
                                {(user.phone + "").padStart(11, "0")}
                            </span>
                        </FlexRowReverse>
                        <FlexRowReverse>
                            <CenterIcon
                                icon={"ic:sharp-alternate-email"}
                                className="text-yellow-400"
                            />
                            <span className="flex-center-both">{user.email}</span>
                        </FlexRowReverse>
                    </div>
                </div>
            </div>
            {profileStatistics ? <UserStatistics /> : ""}
        </>
    );
};

export default UserHome;
