import React from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AboutSection from "../sections/home/AboutSection";
import auth from "../services/authServices";
import Categorie from "../sections/home/CoursesSection/Categorie";
import SectionHead from "../components/ui/SectionHead";
import Button from "../components/ui/Button";

const Year = () => {
    const { id } = useParams();

    const token = auth.getToken();

    return (
        <>
            {token ? (
                <>
                    {/* <div className="flex-center-both w-full">
                        <div className="relative z-10 font-h1 py-20 saturate-50">
                            <Button
                                color="rose"
                                className="px-20 py-4"
                                element="Link"
                                to="/community/community_categories"
                            >
                                منتدى طلبة خالد صقر
                            </Button>
                        </div>
                    </div> */}
                    <ScrollingProvider>
                        <SectionHead title={"اشتراكاتك"} />
                    </ScrollingProvider>
                    <Categorie
                        api={`/api/sellables/subscribed`}
                        noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
                    />
                </>
            ) : (
                <>
                    <MainSection title={getYearPlaceHolder(id)} />
                    <ScrollingProvider>
                        <AboutSection />
                    </ScrollingProvider>
                </>
            )}

            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
        </>
    );
};

export default Year;
